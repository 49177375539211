#root, body, html {
  background: #131720;
}

body {
  animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.animate {
  transform: scale(2) translateY(-50px);
}